<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ $t("statistic.title") }}</h1>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-12">
        <Tabs :tabs="tabs" :currentTab="currentTab" @setTab="setCurrentTab" />
      </div>
    </div>
    <form action="/" method="get" class="statistics-form">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.choose_period") }}</label>
            <div @click="setFocus('date-range')" tabindex="-1" ref="date-range" class="form-item__field multiselect">
              <date-range-picker class="input-mask datepicker form-item__field" :ranges="ranges" :locale-data="dateRangePicker" :opens="'right'" :max-date="new Date()" v-model="selectedDate">
                <template v-slot:input="picker">
                  {{ moment(picker.startDate).format("YYYY-MM-DD") }} -
                  {{ moment(picker.endDate).format("YYYY-MM-DD") }}
                </template>
              </date-range-picker>

              <div class="datepicker-arrow"></div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.format") }}</label>
            <div>
              <custom-multiselect v-model="selectedFormat" :list="selectFormat" class="form-item__field" :allowEmpty="false">
                <template v-slot:block="props">
                  <span v-if="props.value.title === 'All formats'">{{ $t("statistic.all_formats") }}</span>
                  <span v-else>{{ props.value.title }}</span>
                </template>
                <template v-slot:list="props">
                  <span v-if="props.value.title === 'All formats'">{{ $t("statistic.all_formats") }}</span>
                  <span v-else>{{ props.value.title }}</span>
                </template>
              </custom-multiselect>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.country") }}</label>
            <div>
              <custom-multiselect v-if="selectCountries" v-model="selectedCountry" :list="selectCountries" class="form-item__field" :allowEmpty="false" search-by="iso">
                <template v-slot:block="props">
                  <span v-if="props.value.name_en === 'All countries'">{{ $t("statistic.all_countries") }}</span>
                  <span v-else>
                    {{ props.value[`name_${$i18n.locale === "ru" ? "ru" : "en"}`] + ` (${props.value.iso})` }}
                  </span>
                </template>
                <template v-slot:list="props">
                  <span v-if="props.value.name_en === 'All countries'">{{ $t("statistic.all_countries") }}</span>
                  <span v-else>
                    {{ props.value[`name_${$i18n.locale === "ru" ? "ru" : "en"}`] + ` (${props.value.iso})` }}
                  </span>
                </template>
              </custom-multiselect>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <input placeholder="SUB 1" type="text" v-model.trim="sub1" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <input placeholder="SUB 2" type="text" v-model.trim="sub2" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <input placeholder="SUB 3" type="text" v-model.trim="sub3" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <input placeholder="SUB 4" type="text" v-model.trim="sub4" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.browser") }}</label>
            <div>
              <custom-multiselect v-model="selectedBrowsers" :list="selectBrowser" class="form-item__field" :allowEmpty="false">
                <template v-slot:block="props">
                  <span v-if="props.value.title === 'All browsers'">{{ $t("statistic.all_browsers") }}</span>
                  <span v-else>{{ props.value.title }}</span>
                </template>
                <template v-slot:list="props">
                  <span v-if="props.value.title === 'All browsers'">{{ $t("statistic.all_browsers") }}</span>
                  <span v-else>{{ props.value.title }}</span>
                </template>
              </custom-multiselect>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.platform_2") }}</label>
            <custom-multiselect v-model="selectedOs" :list="selectOS" class="form-item__field" :allowEmpty="false">
              <template v-slot:block="props">
                <span v-if="props.value.title === 'All os'">{{ $t("statistic.all_platforms_2") }}</span>
                <span v-else>{{ props.value.title }}</span>
              </template>
              <template v-slot:list="props">
                <span v-if="props.value.title === 'All os'">{{ $t("statistic.all_platforms_2") }}</span>
                <span v-else>{{ props.value.title }}</span>
              </template>
            </custom-multiselect>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3 px-xl-2">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.choose_sub_period") }}</label>
            <div @click="setFocus('sub-range')" tabindex="-1" ref="sub-range" class="form-item__field multiselect">
              <date-range-picker class="input-mask datepicker form-item__field" :ranges="ranges" singleDatePicker="range" :locale-data="dateRangePicker" :opens="'right'" autoApply :max-date="new Date()" v-model="selectedSubDate">
                <template v-slot:input="picker">
                  {{ !selectedSubDate.startDate ? "" : moment(picker.startDate).format("YYYY-MM-DD") }}
                  -
                  {{ !selectedSubDate.endDate ? "" : moment(picker.endDate).format("YYYY-MM-DD") }}
                </template>
              </date-range-picker>
              <svg v-if="selectedSubDate.startDate" @click.exact="resetSelectedSubDate" v-svg symbol="close-red" size="0 0 20 20" class="close" />
              <div class="datepicker-arrow"></div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.platform") }}</label>

            <custom-multiselect :list="selectSubAccounts.filter((el) => el.trash === 0)" v-model="selectedSubAccounts" :allowEmpty="selectedSubAccounts[0].id != 0" :multiply="true" :searchBy="'name'">
              <template v-slot:block="props">
                <span v-if="props.value.id === 0">{{ formatLabel("statistic.all_platforms") }}</span>
                <span v-else>{{ props.value.name }}</span>
              </template>
              <template v-slot:list="props">
                <span>
                  {{ props.value.name }}
                </span>
              </template>
            </custom-multiselect>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.chart") }}</label>
            <custom-multiselect v-model="chartValue" :list="chartOptions" class="form-item__field" :allowEmpty="false">
              <template v-slot:block="props">
                <span v-if="props.value.title === 'All os'">{{ $t("statistic.all_platforms_2") }}</span>
                <span v-else>{{ $t(props.value.title) }}</span>
              </template>
              <template v-slot:list="props">
                <span v-if="props.value.title === 'All os'">{{ $t("statistic.all_platforms_2") }}</span>
                <span v-else>{{ $t(props.value.title) }}</span>
              </template>
            </custom-multiselect>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center pb-2">
          <button class="btn light-button" :class="{ unactive: !showChart }" @click.prevent="showChart = !showChart">
            <svg v-svg :symbol="showChart ? 'view-1' : 'view-2'" class="icon-pass" role="presentation" size="0 0 18 16"></svg>

            {{ $t("statistic.chart") }}
          </button>
        </div>
        <div class="col-12 d-flex align-center justify-content-start">
          <button type="submit" @click.prevent="getStatistics" class="btn _mob100 _bg-green">
            {{ $t("update") }}
          </button>
        </div>
      </div>
    </form>
    <div class="sep mb-3"></div>
    <transition-height>
      <LinesChart :key="$route.fullPath" class="p-0" v-show="showChart && chartData" :title="$t('statistic.by_days')" :simple="true" :series="filteredChartData" :customXAxis="chartDateArray" />
    </transition-height>
    <div class="c-table mt-5 mt-md-0">
      <div class="d-flex justify-content-between px-2 px-md-0" v-show="!loadDataTable">
        <button @click="downloadCSV" :disabled="loadingCSV" class="buttons-csv dt-button buttons-csv buttons-html5">
          CSV
          <img :src="require('@/assets/images/rolling.svg')" alt="loader" width="18" v-show="loadingCSV" />
        </button>
        <button class="buttons-csv dt-button buttons-csv buttons-html5 table-header-button" @click="$modal.show('tableColumn')" :title="$t('site.settings')">
          <svg v-svg symbol="settings" size="0 0 14 14" role="info"></svg>
        </button>
      </div>
      <div class="inner">
        <vue-good-table styleClass="table _no-wrap sortEnable tablesorter tablesorter-bootstrap" :columns="columns" :rows="tableData">
          <template slot="table-column" slot-scope="props">
            <span class="cursor-pointer" @click="toSort(props.column)">
              {{ formatLabel(props.column.label) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'first_column'">
              <span class="text-nowrap">{{ props.row.first_column === "site" ? $t("platform.my_site") : props.row.first_column === "total" ? $t(`${props.row.first_column}`) : props.row.first_column }}</span>
            </span>
            <span v-if="props.column.field === 'clicks'">
              <span class="text-nowrap">{{ props.row.clicks }}</span>
            </span>
            <span v-if="props.column.field === 'uniq_clicks'">
              <span class="text-nowrap">{{ props.row.uniq_clicks }}</span>
            </span>
            <span v-if="props.column.field === 'ratio_percent'">
              <span class="text-nowrap"
                >1:{{ props.row.ratio ? props.row.ratio : 0 }}
                <template v-if="props.row.ratio"> ({{ props.row.ratio_percent }}%) </template>
                <template v-else> (0%) </template>
              </span>
            </span>
            <span v-if="props.column.field === 'subscriptions'">
              <span class="text-nowrap">{{ props.row.subscriptions }}</span>
            </span>
            <span v-if="props.column.field === 'unsubscriptions'">
              <span class="text-nowrap">{{ props.row.unsubscriptions }}</span>
            </span>
            <span v-if="props.column.field === 'traffic_back'">
              <span class="text-nowrap">{{ props.row.traffic_back }}</span>
            </span>
            <span v-if="props.column.field === 'traffic_back_lead'">
              <span class="text-nowrap">{{ props.row.traffic_back_lead }}</span>
            </span>
            <span v-if="props.column.field === 'traffic_back_profit'">
              <span class="text-nowrap">{{ props.row.traffic_back_profit }}</span>
            </span>
            <span v-if="props.column.field === 'cpl_count'">
              <span class="text-nowrap">{{ props.row.cpl_count }}</span>
            </span>
            <span v-if="props.column.field === 'cpl_profit'">
              <span class="text-nowrap">{{ props.row.cpl_profit }}</span>
            </span>
            <span v-if="props.column.field === 'rs_clicks'">
              <span class="text-nowrap">{{ props.row.rs_clicks | formatNumberWithSpaces }}</span>
            </span>
            <span v-if="props.column.field === 'rs_shows'">
              <span class="text-nowrap">{{ props.row.rs_shows | formatNumberWithSpaces }}</span>
            </span>
            <span v-if="props.column.field === 'rs_ctr'">
              <span class="text-nowrap">{{ props.row.rs_ctr }}</span>
            </span>
            <span v-if="props.column.field === 'rs_income'">
              <span class="text-nowrap">{{ props.row.rs_income }}</span>
            </span>
            <span v-if="props.column.field === 'rs_avg_cpc'">
              <span class="text-nowrap">{{ props.row.rs_avg_cpc }}</span>
            </span>
            <span v-if="props.column.field === 'cpc_m'">
              <span class="text-nowrap">{{ (props.row.rs_avg_cpc * 1000) | roundNumber }}</span>
            </span>
            <span v-if="props.column.field === 'rs_cpm'">
              <span class="text-nowrap">{{ props.row.rs_cpm || 0 }}</span>
            </span>
            <span v-if="props.column.field === 'total_profit'">
              <span class="text-nowrap">{{ props.row.total_profit }}</span>
            </span>
          </template>
          <div class="d-flex justify-content-center" slot="emptystate">
            <template v-if="!loadDataTable">
              {{ $t("any_results") }}
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <img :src="require('@/assets/images/rolling.svg')" alt="loader" class="loader" />
              </div>
            </template>
          </div>
        </vue-good-table>
      </div>
    </div>

    <div class="actions">
      <button class="btn btn-prev" @click="setPage(currentPage - 1)" :disabled="currentPage === 1">
        <svg v-svg symbol="arrow" class="mr-2 arrow-left" size="0 0 17 17" role="info" />
        {{ $t("statistic.prev") }}
      </button>
      <button class="btn btn-next" @click="setPage(currentPage + 1)" :disabled="currentPage === statsPage">
        {{ $t("statistic.next") }}
        <svg v-svg class="ml-2" symbol="arrow" size="0 0 17 17" role="info" />
      </button>
      <custom-multiselect v-model="rowsPerPage" :list="perPageOptions" class="per-page" :allowEmpty="false">
        <template v-slot:block="props">
          <span>
            {{ props.value }}
          </span>
        </template>
        <template v-slot:list="props">
          <span>
            {{ props.value }}
          </span>
        </template>
      </custom-multiselect>
    </div>

    <modal :width="398" height="auto" name="tableColumn" classes="creative-modal" :scrollable="true">
      <TableColumnModal :columns="columns" @updateColumns="setColumnsToLocalData" @closeModal="closeModalColumn" />
    </modal>
    <alert v-if="$alert.title" />
  </div>
</template>

<script>
import ServiceStatistics from "@/api/serviceStatistics";
import { mapGetters, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "@/assets/css/_daterangepicker.scss";
import "@/assets/css/_section.scss";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import TableColumnModal from "@/components/TableColumnModal.vue";
import Tabs from "@/components/statictics/Tabs.vue";

import moment from "moment";
import LinesChart from "@/components/Charts/LinesChart.vue";

export default {
  name: "Statistic",
  components: {
    Tabs,
    DateRangePicker,
    TableColumnModal,
    LinesChart,
  },
  beforeMount() {
    document.title = `Partners.House | ${this.$t("statistic.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("statistic.title")}`;
  },

  data() {
    return {
      SORT_DEFAULT: "clicks",
      ORDER_DEFAULT: null,

      loadingCSV: false,
      currentPage: 1,
      statsPage: 0,
      loadDataTable: false,
      tableData: [],
      totals: [],
      currentTab: "day",
      tabs: ["day", "hour", "format", "country", "os", "os_version", "browser", "subacc", "landing", "sub1", "sub2", "sub3", "sub4"],
      selectedCountry: { name_ru: "Все страны", name_en: "All countries" },
      selectedBrowsers: {
        title: "All browsers",
        value: "All browsers",
      },
      selectedFormat: {
        title: "All formats",
        value: "All formats",
      },
      selectedOs: {
        title: "All os",
        value: "All os",
      },
      selectFormat: [
        {
          title: "All formats",
          value: "All formats",
        },
        {
          title: "Push",
          value: "Push",
        },
        {
          title: "InPage",
          value: "InPage",
        },
        {
          title: "OnClick",
          value: "Pop",
        },
      ],
      selectedSubAccounts: [{ name: "All platforms", id: 0, trash: 0 }],
      sub1: "",
      sub2: "",
      sub3: "",
      sub4: "",
      sort: this.SORT_DEFAULT,
      order: this.ORDER_DEFAULT,
      selectedDate: {
        startDate: this.moment().subtract(6, "days")._d,
        endDate: this.moment()._d,
      },
      selectedSubDate: {
        startDate: null,
        endDate: null,
      },
      dateRangePicker: {
        firstDay: 1,
        format: "yyyy.mm.dd",
        separator: "-",
        applyLabel: this.$t("apply"),
        cancelLabel: this.$t("cancel"),
        daysOfWeek: [this.$t("date.weeks.su"), this.$t("date.weeks.mo"), this.$t("date.weeks.tu"), this.$t("date.weeks.we"), this.$t("date.weeks.th"), this.$t("date.weeks.fr"), this.$t("date.weeks.sa")],
        monthNames: [this.$t("date.month.jan"), this.$t("date.month.feb"), this.$t("date.month.mar"), this.$t("date.month.apr"), this.$t("date.month.may"), this.$t("date.month.jun"), this.$t("date.month.jul"), this.$t("date.month.aug"), this.$t("date.month.sep"), this.$t("date.month.oct"), this.$t("date.month.nov"), this.$t("date.month.dec")],
      },
      ranges: {
        [this.$t("statistic.today")]: [moment()._d, moment()._d],
        [this.$t("statistic.yesterday")]: [moment().subtract(1, "days")._d, moment().subtract(1, "days")._d],
        [this.$t("statistic.last_seven_days")]: [moment().subtract(6, "days")._d, moment()._d],
        [this.$t("statistic.last_thirty_days")]: [moment().subtract(29, "days")._d, moment()._d],
        [this.$t("statistic.this_month")]: [moment().startOf("month")._d, moment().endOf("month")._d],
        [this.$t("statistic.last_month")]: [moment().subtract(1, "month").startOf("month")._d, moment().subtract(1, "month").endOf("month")._d],
        [this.$t("statistic.this_year")]: [moment().startOf("year")._d, moment()._d],
        [this.$t("statistic.last_year")]: [moment().subtract(1, "years").startOf("year")._d, moment().subtract(1, "years").endOf("year")._d],
      },
      // columnsNew: [],
      firstNameColumn: "first_name",
      columns: [
        {
          label: "statistic.data",
          field: "first_column",
          thClass: `sortable spec-sort`,
          sortable: false,
          firstColumn: true,
          checked: true,
          disabled: true,
          hidden: false,
          available: true,
        },
        {
          label: "statistic.table.clicks",
          field: "clicks",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "visits",
        },
        {
          label: "statistic.table.uniq_clicks",
          field: "uniq_clicks",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "visits",
        },
        {
          label: "statistic.table.tb",
          field: "traffic_back",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "traffic_back",
        },
        {
          label: "statistic.table.tb_leads",
          field: "traffic_back_lead",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "traffic_back",
        },
        {
          label: "statistic.table.tb_profit",
          field: "traffic_back_profit",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "traffic_back",
        },
        {
          label: "statistic.table.cpl_count",
          field: "cpl_count",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "cpl",
        },
        {
          label: "statistic.table.cpl_profit",
          field: "cpl_profit",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "cpl",
        },
        {
          label: "statistic.table.ratio",
          field: "ratio_percent",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "push_subs",
        },
        {
          label: "statistic.table.subscriptions",
          field: "subscriptions",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "push_subs",
        },
        {
          label: "statistic.table.unsubscriptions",
          field: "unsubscriptions",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "push_subs",
        },
        {
          label: "statistic.table.rs_clicks",
          field: "rs_clicks",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "traffic",
        },
        {
          label: "statistic.table.rs_cpm",
          field: "rs_shows",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "traffic",
        },
        {
          label: "statistic.table.rs_ctr",
          field: "rs_ctr",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "traffic",
        },
        {
          label: "statistic.table.rs_cpc",
          field: "rs_avg_cpc",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "metrics",
        },
        {
          label: "statistic.table.cpc_m",
          field: "cpc_m",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "metrics",
        },
        {
          label: "statistic.table.rs_cpm2",
          field: "rs_cpm",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "metrics",
        },
        {
          label: "statistic.table.rs_income",
          field: "rs_income",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: false,
          hidden: false,
          available: true,
          category: "profit",
        },
        {
          label: "statistic.table.total_profit",
          field: "total_profit",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
          hidden: false,
          available: true,
          category: "profit",
        },
      ],
      mountedPlatformIds: null,
      rowsPerPage: 50,
      perPageOptions: [25, 50, 100, 200, 500, 1000],

      chartOptions: [
        {
          title: "dashboard.general_info.clicks",
          value: 1,
          id: "clicks",
        },
        {
          title: "statistic.table.subscriptions",
          value: 2,
          id: "subscriptions",
        },
        {
          title: "statistic.table.ratio",
          value: 3,
          id: "ratio",
        },
        {
          title: "dashboard.general_info.rs_clicks",
          value: 4,
          id: "rs_clicks",
        },
        {
          title: "statistic.table.cpc",
          value: 5,
          id: "cpc",
        },
        {
          title: "statistic.table.income",
          value: 6,
          id: "income",
        },
        {
          title: "statistic.table.cpm",
          value: 7,
          id: "cpm",
        },
        {
          title: "statistic.table.show",
          value: 8,
          id: "shows",
        },
      ],
      chartValue: {
        title: "dashboard.general_info.clicks",
        value: 1,
        id: "clicks",
      },
      showChart: true,
      chartData: null,
      chartDataId: "",
    };
  },
  watch: {
    showChart: {
      handler(val) {
        localStorage.setItem("showLinesChart", val);
      },
    },
    currentTab: {
      deep: true,
      handler(val) {
        this.ORDER_DEFAULT = val === "country" || val === "os" ? "desc" : null;
        this.SORT_DEFAULT = val === "day" || val === "hour" ? val : "clicks";
        this.currentPage = 1;
        let currentUrl = window.location.href;
        currentUrl = currentUrl.replace(/[?&]page=\d+/, "");
        window.history.replaceState({}, "", currentUrl);

        this.clearFilterOrder();
        this.changeTableOrder();
        this.updateColumns();
        this.getStatistics();
      },
    },
    rowsPerPage(val) {
      localStorage.setItem("rows_per_page", val);
      this.getStatistics();
    },
    "$i18n.locale": function () {
      this.getStatistics();

      if (this.selectedSubAccounts.find((el) => el.id === 0)) {
        this.selectedSubAccounts.find((el) => el.id === 0).name = "All platforms";
      }
    },
    selectedSubAccounts: function (val, newVal) {
      if (newVal.findIndex((obj) => obj.id === 0) === -1 && val.findIndex((obj) => obj.id === 0) !== -1) {
        this.selectedSubAccounts = [{ name: "All platforms", id: 0, trash: 0 }];
      } else {
        if (this.selectedSubAccounts.length === 0) {
          this.selectedSubAccounts = [{ name: "All platforms", id: 0, trash: 0 }];
        }
        if (this.selectedSubAccIds.length !== 0) {
          const indexToRemove = this.selectedSubAccounts.findIndex((obj) => obj.id === 0);

          if (indexToRemove !== -1) {
            this.selectedSubAccounts.splice(indexToRemove, 1);
          }
        }
      }
      this.updateChart();
    },
    order: function () {
      this.checkTableSpan();
    },
    sort: function () {
      this.checkTableSpan();
    },
    selectSubAccounts: {
      immediate: false,
      handler() {
        this.addStartPlatform();
      },
    },
    sub1() {
      this.updateChart();
    },
    sub2() {
      this.updateChart();
    },
    sub3() {
      this.updateChart();
    },
    sub4() {
      this.updateChart();
    },
    selectBrowser: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    selectedOs: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    selectedFormat: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    selectedSubDate: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    chartValue: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    selectedDate: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    featureFlags: {
      handler(val) {
        if (val) {
          if (!val.some((el) => el === "unsubscriptions")) {
            this.columns = this.columns.filter((el) => {
              return el.field !== "unsubscriptions";
            });
          }
          this.getColumnsFromLocalData();
        }
      },
    },
    columns: {
      deep: true,
      handler() {
        this.columns.forEach((el) => {
          if (!el.available) {
            el.hidden = true;
          } else {
            el.hidden = !el.checked;
          }
        });
      },
    },
  },
  computed: {
    ...mapGetters({
      selectBrowser: "statistics/getBrowsers",
      selectCountries: "statistics/getCountries",
      selectOS: "statistics/getOS",
      selectSubAccounts: "statistics/getSubAccounts",
      featureFlags: "user/getFeatureFlags",
    }),
    selectedSubAccIds() {
      const selectedArray = [];
      this.selectedSubAccounts.forEach((e) => {
        if (e.id !== 0) return selectedArray.push(e.id);
      });
      return selectedArray;
    },
    chartDateArray() {
      if (this.chartData) {
        const numbersArray = this.chartData[this.chartDataId];
        const today = new Date();
        const result = [];

        for (let i = 0; i < numbersArray.length; i++) {
          const date = new Date(today);
          date.setDate(today.getDate() - i);
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}.${month}.${year}`;
          result.push(formattedDate);
        }

        return result.reverse();
      } else {
        return [];
      }
    },
    filteredChartData() {
      if (this.chartData) {
        const cloneData = this.chartData[this.chartDataId];
        const label = this.chartOptions.find((el) => el.id === this.chartDataId).title;
        return [
          {
            name: this.$formatLabel(label),
            title: "",
            show: true,
            marker: {
              symbol: "circle",
              radius: 3,
            },
            lineWidth: 1,
            color: "#D55F74",
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            data: cloneData,
          },
        ];
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (localStorage.getItem("showLinesChart")) {
      this.showChart = JSON.parse(localStorage.getItem("showLinesChart"));
    }
    if (localStorage.getItem("rows_per_page")) this.rowsPerPage = localStorage.getItem("rows_per_page");
    else this.getStatistics();

    this.updateChart();

    this.setSubAccounts();
    this.getColumnsFromLocalData();

    this.updateColumns();

    if (localStorage.getItem("campaignsColumns")) {
      localStorage.removeItem("campaignsColumns");
    }

    if (localStorage.getItem("statisticPlatform")) {
      this.mountedPlatformIds = localStorage.getItem("statisticPlatform");
      localStorage.removeItem("statisticPlatform");
    }
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
      setSubAccounts: "statistics/handleSubAccounts",
    }),
    setColumnsToLocalData(value) {
      const columns = value.reduce((acc, el) => {
        if (el.checked) {
          acc.push(el.field);
        }
        return acc;
      }, []);

      localStorage.setItem("statisticTableColumns", JSON.stringify(columns));
      this.getColumnsFromLocalData();
    },
    getColumnsFromLocalData() {
      const columns = JSON.parse(localStorage.getItem("statisticTableColumns"));
      if (!columns) {
        return;
      }

      this.columns.forEach((el) => {
        el.checked = columns.includes(el.field);
        el.hidden = !columns.includes(el.field);
      });
    },
    updateColumns() {
      const subTabs = ["sub1", "sub2", "sub3", "sub4"];
      if (this.currentTab !== "subacc") {
        if (this.currentTab == "landing") {
          this.columns.forEach((el) => {
            if (el.field.includes("rs_")) {
              el.available = false;
            } else {
              el.available = true;
            }
          });
        } else if (this.currentTab === "os_version" || subTabs.includes(this.currentTab)) {
          this.columns.forEach((el) => {
            if (el.field == "rs_ctr" || el.field == "rs_shows") {
              el.available = false;
            } else {
              el.available = true;
            }
          });
        } else {
          this.columns.forEach((el) => {
            if (el.field == "feed") {
              el.available = false;
            } else {
              el.available = true;
            }
          });
        }
      } else if (this.currentTab == "feed") {
        this.columns.forEach((el) => {
          if (el.field == "shows" || el.field == "ctr" || el.field == "feed") {
            el.available = false;
          } else {
            el.available = true;
          }
        });
      } else {
        this.columns.forEach((el) => {
          el.available = true;
        });
      }
    },
    downloadCSV() {
      this.loadingCSV = true;
      const params = {
        start_date: this.moment(this.selectedDate.startDate).format("YYYY-MM-DD"),
        end_date: this.moment(this.selectedDate.endDate).format("YYYY-MM-DD"),
        start_sub_date: this.selectedSubDate.startDate ? this.moment(this.selectedSubDate.startDate).format("YYYY-MM-DD") : null,
        end_sub_date: this.selectedSubDate.endDate ? this.moment(this.selectedSubDate.endDate).format("YYYY-MM-DD") : null,
        group_by: this.currentTab,
        country: this.selectedCountry.iso,
        format: this.selectedFormat.value === "All formats" ? null : this.selectedFormat.value,
        browser: this.selectedBrowsers.value === "All browsers" ? null : this.selectedBrowsers.value,
        sub_accounts: this.selectedSubAccIds,
        os: this.selectedOs.value === "All os" ? null : this.selectedOs.value,
        sub1: this.checkNull(this.sub1),
        sub2: this.checkNull(this.sub2),
        sub3: this.checkNull(this.sub3),
        sub4: this.checkNull(this.sub4),
        sort: this.sort,
        order: this.order,
        page: this.loadingCSV ? undefined : this.currentPage,
      };

      ServiceStatistics.downloadCSV(params)
        .then((resp) => {
          if (!resp) {
            this.$alert({
              title: this.$t("sent_error"),
            });

            return;
          }

          const blob = new Blob([resp], { type: "text/csv" });
          const downloadLink = document.createElement("a");

          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.setAttribute("download", "statistics.csv");
          document.body.appendChild(downloadLink);
          downloadLink.click();
        })
        .finally(() => (this.loadingCSV = false));
    },
    setPage(page) {
      this.currentPage = page;

      let currentUrl = window.location.href;
      let paramToAdd = "page=" + this.currentPage;

      if (currentUrl.includes("page=")) {
        currentUrl = currentUrl.replace(/page=\d+/, paramToAdd);
      } else {
        currentUrl += (currentUrl.includes("?") ? "&" : "?") + paramToAdd;
      }
      window.history.replaceState({}, "", currentUrl);
      this.getStatistics();
    },
    resetSelectedSubDate() {
      this.selectedSubDate = {
        startDate: null,
        endDate: null,
      };
    },
    clearFilterOrder() {
      this.sort = this.SORT_DEFAULT;
      this.order = this.ORDER_DEFAULT;
    },
    addStartPlatform() {
      if (this.mountedPlatformIds) {
        let res = this.selectSubAccounts.find((el) => {
          return el.id == this.mountedPlatformIds;
        });
        if (!this.selectedSubAccounts.includes(res)) {
          this.selectedSubAccounts.push(res);
          this.getStatistics();
        }
      }
    },
    customCountriesLabel({ name_en, name_ru, iso }) {
      return `${this.$i18n.locale === "ru" ? name_ru : name_en}` + ` (${iso})`;
    },
    moment: function (e) {
      return moment(e);
    },
    closeModalColumn() {
      this.$modal.hide("tableColumn");
    },
    setCurrentTab(tab) {
      this.currentTab = tab;
    },
    setFocus(name) {
      this.$refs[name].focus();
    },
    formatLabel(el) {
      return this.$t(el);
    },
    checkTableSpan() {
      const thElements = document.querySelectorAll("th.sortable"); // all sortable table columns
      thElements.forEach((thElement) => {
        const spanElement = thElement.querySelector("span"); // span of an current iteration th
        if (spanElement && this.sort && this.order && spanElement.innerText === this.$t(this.columns.find((el) => el.field === this.sort).label)) {
          // check if an span text === current sort value text
          thElement.classList.remove(
            // remove oposite class
            this.order && this.order === "desc" ? "sorting-asc" : "sorting-desc"
          );
          thElement.classList.add(
            // add class to the current sort th
            this.order && this.order === "desc" ? "sorting-desc" : "sorting-asc"
          );
          return false;
        } else {
          // if no remove all classes
          thElement.classList.remove("sorting-desc", "sorting-asc");
        }
      });
    },
    toSort(val) {
      if (val.field === "real_name") {
        this.sort = this.currentTab;
      } else {
        this.order = this.sort === val.field ? (this.order === "asc" ? "desc" : "asc") : "desc";
        this.sort = val.field;
      }
      this.getStatistics(val.field);
    },
    changeTableOrder() {
      switch (this.currentTab) {
        case "day":
          this.firstNameColumn = "statistic.data";
          break;
        case "hour":
          this.firstNameColumn = "statistic.tabs.hour";
          break;
        case "country":
          this.firstNameColumn = "statistic.tabs.country";
          break;
        case "format":
          this.firstNameColumn = "statistic.tabs.format";
          break;
        case "os":
          this.firstNameColumn = "statistic.tabs.os";
          break;
        case "os_version":
          this.firstNameColumn = "statistic.tabs.os_version";
          break;
        case "browser":
          this.firstNameColumn = "statistic.tabs.browser";
          break;
        case "subacc":
          this.firstNameColumn = "statistic.tabs.subacc";
          break;
        case "landing":
          this.firstNameColumn = "statistic.tabs.landing";
          break;
        case "sub1":
          this.firstNameColumn = "statistic.tabs.sub1";
          break;
        case "sub2":
          this.firstNameColumn = "statistic.tabs.sub2";
          break;
        case "sub3":
          this.firstNameColumn = "statistic.tabs.sub3";
          break;
        case "sub4":
          this.firstNameColumn = "statistic.tabs.sub4";
          break;
        default:
          this.firstNameColumn = "statistic.data";
      }
      this.columns[0].label = this.firstNameColumn;
    },
    setTable() {
      const index = this.columns.findIndex((x) => x.firstColumn === true);
      this.setFirstPosition(this.columns, index, 0);
    },
    setFirstPosition(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    },
    checkNull(val) {
      return val ? val : null;
    },
    updateChart() {
      const params = {
        start_date: this.moment(this.selectedDate.startDate).format("YYYY-MM-DD"),
        end_date: this.moment(this.selectedDate.endDate).format("YYYY-MM-DD"),
        start_sub_date: this.selectedSubDate.startDate ? this.moment(this.selectedSubDate.startDate).format("YYYY-MM-DD") : null,
        end_sub_date: this.selectedSubDate.endDate ? this.moment(this.selectedSubDate.endDate).format("YYYY-MM-DD") : null,
        country: this.selectedCountry.iso || null,
        format: this.selectedFormat.value === "All formats" ? null : this.selectedFormat.value,
        browser: this.selectedBrowsers.value === "All browsers" ? null : this.selectedBrowsers.value,
        sub_accounts: this.selectedSubAccIds,
        os: this.selectedOs.value === "All os" ? null : this.selectedOs.value,
        sub1: this.checkNull(this.sub1),
        sub2: this.checkNull(this.sub2),
        sub3: this.checkNull(this.sub3),
        sub4: this.checkNull(this.sub4),
      };
      ServiceStatistics.getStatisticsChartData(params).then((res) => {
        if (res && res.status === 200) {
          this.chartDataId = this.chartValue.id;
          this.chartData = { ...res.data };
        }
      });
    },
    getStatistics() {
      const params = {
        start_date: this.moment(this.selectedDate.startDate).format("YYYY-MM-DD"),
        end_date: this.moment(this.selectedDate.endDate).format("YYYY-MM-DD"),
        start_sub_date: this.selectedSubDate.startDate ? this.moment(this.selectedSubDate.startDate).format("YYYY-MM-DD") : null,
        end_sub_date: this.selectedSubDate.endDate ? this.moment(this.selectedSubDate.endDate).format("YYYY-MM-DD") : null,
        group_by: this.currentTab,
        country: this.selectedCountry.iso || null,
        format: this.selectedFormat.value === "All formats" ? null : this.selectedFormat.value,
        browser: this.selectedBrowsers.value === "All browsers" ? null : this.selectedBrowsers.value,
        sub_accounts: this.selectedSubAccIds,
        os: this.selectedOs.value === "All os" ? null : this.selectedOs.value,
        sub1: this.checkNull(this.sub1),
        sub2: this.checkNull(this.sub2),
        sub3: this.checkNull(this.sub3),
        sub4: this.checkNull(this.sub4),
        sort: this.sort,
        order: this.order,
        page: this.currentPage !== 1 ? this.currentPage : undefined,
        rows_per_page: this.rowsPerPage,
      };

      this.tableData = [];
      this.loadDataTable = true;
      ServiceStatistics.getStatistics(params)
        .then((res) => {
          if (res && res.data?.list) {
            this.setUser();
            this.tableData = res.data.list;
            this.totals = res.data.totals;
            this.statsPage = res.data.pages || 0;
            this.setTable();
          } else {
            this.$alert({
              title: this.$t("sent_error"),
              text: res.message,
            });
          }
        })
        .finally(() => {
          this.loadDataTable = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.actions {
  display: flex;
  background: transparent;
  margin-top: 20px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 30px;
  }

  .btn {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    border-radius: 0;
    padding: 0 20px;
    min-width: 120px;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &.btn-prev {
      color: #141414;
      background: #ececf3;
      border-radius: 50px 0 0 50px;
      &:hover {
        background: #dcdce0;
        color: #141414;
      }
      svg.arrow-left {
        fill: #000;
        transform: rotate(180deg);
      }
    }
    &.btn-next {
      background: #37807d;
      color: #fff;
      border-radius: 0 50px 50px 0;
      &:hover {
        background: #57908f;
      }
      svg {
        fill: #fff;
      }
    }
  }
  .per-page {
    margin-left: 20px;
    min-width: 90px;
    width: auto;
  }
}

.form-item__field:focus {
  .input-mask {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 0 1px #000109 !important;
  }

  .datepicker-arrow {
    transform: scale(1, -1);
  }
}

.loader {
  height: 40px;
}

.table-header-button {
  svg {
    fill: #5d5d68;
  }
}

.input-mask {
  &::v-deep {
    .reportrange-text {
      position: absolute;
      display: flex;
      align-items: center;
      border-color: transparent;
      left: 0;
      height: 45px;
      font-weight: 500;
      color: #212529;
      background: transparent;
      padding: 5px 10px 5px 45px;
    }

    .daterangepicker {
      top: 45px;
    }
  }
}

.multiselect {
  &::v-deep {
    .multiselect__tags {
      flex-direction: column;
      align-items: flex-start;
    }

    .multiselect__input {
      padding: 8px 5px;
      border: none;
      vertical-align: middle;
      border-color: transparent;
      height: auto !important;
      display: none;
      margin-bottom: 0;
      box-shadow: none !important;
    }
  }

  &.multiselect--active {
    &::v-deep {
      .multiselect__input {
        display: block;
      }
    }
  }
}

.buttons-csv {
  background-color: #f3f4f9;
  color: #5d5d68;
  padding: 6px 14px;
  border-radius: 12px;
  outline: none;
  border: 0;
  transition: all 0.3s;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
svg.close {
  position: absolute;
  cursor: pointer;
  right: 10px;
  bottom: 32px;
}
</style>
